<template>
  <div class="pb-1">
    <!-- ANCHOR - Confirm -->
    <div v-if="isShowConfirm">
      <ConfirmDataBooking
        :payload="payload"
        @back="() => isShowConfirm = false"
        @saveBooking="saveBookingHandle"
      />
    </div>

    <div v-show="!isShowConfirm">
      <NavBarSearch :payload="payloadSearch" />

      <ResultHeaderTab />

      <!-- ANCHOR - KẾT QUẢ TÌM KIẾM TÀU -->
      <ValidationObserver ref="refCreateTicketPaxInput">
        <b-tabs
          v-model="tabIndex"
          nav-wrapper-class="d-none"
        >
          <b-tab
            v-for="(_, itineraryIndex) in getSearchPayloadArray"
            :key="itineraryIndex"
          >
            <template v-if="loading">
              <div class="d-flex-center text-primary mt-2 py-2">
                <b-spinner
                  variant="primary"
                  class="mr-50"
                />
                <span>{{ $t('train.search.result.searching') }}</span>
              </div>
            </template>
            <template v-else-if="isEmpty(getResultTrainArray[itineraryIndex]) && !loading">
              <b-alert
                show
                variant="warning"
                class="my-2 my-lg-3"
              >
                <div class="text-center alert-heading font-medium-4">
                  {{ $t('train.search.result.noTrainFound') }}
                </div>
              </b-alert>
            </template>
            <template v-else-if="!isEmpty(getResultTrainArray[itineraryIndex])">
              <div>
                <TrainFront
                  :itineraryIndex="itineraryIndex"
                  :trains="getResultTrainArray[itineraryIndex]"
                />
              </div>
              <div>
                <TrainCoachList :itineraryIndex="itineraryIndex" />
                <!-- <TrainCoaches :itineraryIndex="itineraryIndex" /> -->
              </div>

              <!-- <div>
                      <TrainCoachLayout :itineraryIndex="itineraryIndex" />
                    </div> -->
              <!-- <div>
                      <AddonsAndPromotions :itineraryIndex="itineraryIndex" />
                    </div> -->

              <div>
                <CardPaxData :itineraryIndex="itineraryIndex" />
              </div>
            </template>
          </b-tab>
        </b-tabs>

        <!-- ANCHOR - ĐIỀN MÃ GIẢM GIÁ, TỔNG GIÁ -->
        <b-row
          class="align-items-center py-75 gap-4"
          no-gutters
        >
          <b-col class="py-25">
            <b-button
              variant="outline-danger"
              class="px-75 text-nowrap"
              size="sm"
              @click="deleteAllSelectTicket"
            >
              {{ isMobileView ? $t('train.search.result.clearAllTickets') : $t('train.search.result.clearSelectedTickets') }}
            </b-button>
          </b-col>
          <b-col class="d-flex order-2 order-sm-0 py-25">
            <b-form-input
              v-model="promotionInput"
              style="min-width: 150px"
              class="mr-50"
              size="sm"
              lazy-formatter
              :formatter="removeAccentsAndTrimAll"
              trim
              :placeholder="$t('train.search.result.enterDiscountCode')"
            />
            <b-button
              variant="outline-info"
              class="text-nowrap px-75"
              size="sm"
              @click="handleAppliesPromotion"
            >
              {{ $t('train.search.result.apply') }}
            </b-button>
          </b-col>
          <b-col class="fw-800 text-success text-right text-nowrap py-25">
            <span class="font-medium-1 mr-1">
              {{ $t('train.search.result.totalAmount') }}
            </span>
            <span class="font-medium-3 mr-1">
              {{ formatCurrency(getTotalCartTicketPrice) }}
            </span>
          </b-col>
        </b-row>

        <!-- SECTION - AGENCY, CUSTOMER -->
        <div>
          <BCardGroup
            class="mb-50 mb-md-1"
            deck
            style="order: 2"
          >
            <!-- ANCHOR Thông tin AGC -->
            <BookingAgency
              :agency-data.sync="agencyData"
              :is-collapsed="isCollapsedAgencyAndContactDetail"
              @toggleCollapse="toggleIsCollapsedAgencyAndContactDetail"
            />
            <!-- ANCHOR Thông tin khách hàng -->
            <BookingContact
              v-if="customerData && defaultCustomerData"
              :customer-data.sync="customerData"
              :defaultCustomerData="defaultCustomerData"
              :is-collapsed="isCollapsedAgencyAndContactDetail"
              @toggleCollapse="toggleIsCollapsedAgencyAndContactDetail"
            />
          </BCardGroup>
        </div>
      </ValidationObserver>

      <!-- ANCHOR - ĐIỀU KHOẢN & DỊCH VỤ -->
      <div class="mt-2">
        <BFormCheckbox
          v-model="isConfirmPolicy"
          class="fw-700 ml-1"
        >
          <div :class="isMobileView ? 'font-small-2' : 'font-medium-1'">
            {{ $t('train.search.result.termsAndConditions') }}
          </div>
        </BFormCheckbox>
      </div>

      <!-- ANCHOR - BUTTON: BACK, CONFIRM -->
      <div class="d-flex-between mt-2">
        <b-button
          variant="outline-secondary"
          pill
          @click="handleBackToSearch"
        >
          {{ $t('train.search.result.goBack') }}
        </b-button>
        <b-button
          variant="success"
          pill
          @click="handleCreateTicket"
        >
          {{ $t('train.search.result.confirmInfo') }}
        </b-button>
      </div>
    </div>

    <ModalCheckAdultTicket @confirm="checkAdultTicketConfirmHandle" />
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BTabs, BTab, BAlert,
  BFormCheckbox, BCardGroup, BFormInput,
  BImg, BContainer, BCard,
  BSpinner,
} from 'bootstrap-vue'
import {
  ref,
  computed,
  onBeforeMount,
  onUnmounted,
  watch,
  nextTick,
  set,
  onBeforeUnmount,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import { ValidationObserver } from 'vee-validate'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'
import { removeAccentsAndTrimAll } from '@/@core/comp-functions/forms/formatter-input'

import {
  convertISODateTime,
  formatCurrency,
} from '@core/utils/filter'

import useTrainHandle from '@train/useTrainHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BContainer,
    BImg,
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    BAlert,
    BSpinner,
    BCardGroup,
    BFormCheckbox,
    BFormInput,

    ValidationObserver,

    // AddonsAndPromotions: () => import('./components/AddonsAndPromotions.vue'),
    // CartTicket: () => import('./components/CartTicket.vue'),
    // PriceShowConfig: () => import('./components/PriceShowConfig.vue'),
    // TrainCoaches: () => import('./components/TrainCoaches.vue'),
    // TrainCoachLayout: () => import('./components/TrainCoachLayout.vue'),

    NavBarSearch: () => import('../train-search/NavBarSearch.vue'),
    ResultHeaderTab: () => import('./components/ResultHeaderTab.vue'),
    TrainFront: () => import('./components/TrainFront.vue'),
    TrainCoachList: () => import('./components/TrainCoachList.vue'),
    // CreateTicketPaxInput: () => import('./components/CreateTicketPaxInput.vue'),
    CardPaxData: () => import('./components/CardPaxData.vue'),
    ConfirmDataBooking: () => import('./components/ConfirmDataBooking.vue'),
    BookingContact: () => import('./components/BookingContact.vue'),
    BookingAgency: () => import('./components/BookingAgency.vue'),
    ModalCheckAdultTicket: () => import('./components/ModalCheckAdultTicket.vue'),
  },
  setup() {
    const { router } = useRouter()
    const { toastWarning, toastSuccess, toastError } = useToast()

    const {
      blankCustomer,
      loading,
      searchTrain,
      stateTicketSelectedArray,

      getBookingCode,
      getIsSearchRT,
      getTotalCartTicketPrice,
      getSearchPayloadArray,
      getResultTrainArray,

      deleteAllSelectTicket,
      resolveDiscountPriceByPassengerCode,
      handleSetTabIndex,
      dropTicketHandle,
    } = useTrainHandle()

    const payloadSearch = JSON.parse(localStorage.getItem('trainSearch'))
    const isConfirmPolicy = ref(false)
    const payload = ref(null)
    const isShowConfirm = ref(false)
    const isBookingCreated = ref(false)

    const tabIndex = computed({
      get() { return store.state['app-train'].tabIndex },
      set(newValue) { store.commit('app-train/SET_TAB_INDEX', newValue) },
    })

    onBeforeMount(() => {
      if (!payloadSearch) {
        toastWarning({ title: 'train.search.result.pleaseSearchAgain' })
        router.push({ name: 'apps-trainSearch' })
      } else {
        searchTrain(payloadSearch)
      }

      if (isShowConfirm.value && !payload.value) {
        isShowConfirm.value = false
      }
    })

    onBeforeUnmount(() => {
      if (!isBookingCreated.value) {
        dropTicketHandle(stateTicketSelectedArray.value.flat())
      }
    })

    onUnmounted(() => {
      store.commit('app-train/RESET_STORE')

      // if (store.hasModule('app-train')) {
      //   store.unregisterModule('app-train')
      // }
    })

    // SECTION - THÔNG TIN ĐẠI LÝ, LIÊN HỆ
    const agencyDataStore = computed(() => store.getters['userStore/getAgencyData'])
    const meDataStore = computed(() => store.getters['userStore/getMeData'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    // const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    // const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const agencyData = ref({})
    const defaultCustomerData = ref()
    const customerData = ref({ ...blankCustomer })

    function importInfoAgency() {
      if (isEmpty(agencyData.value)) return
      defaultCustomerData.value = {
        email: agencyData.value.emailAddress,
        phone: agencyData.value.phoneNumber,
      }
    }

    watch([
      () => isRoleF1.value,
      () => agencyDataStore.value,
      () => meDataStore.value,
    ], ([roleF1, newAgencyDataStore, meData]) => {
      if (!newAgencyDataStore || !meData) return
      if (roleF1) {
        set(agencyData, 'value', { ...meData })
      } else {
        set(agencyData, 'value', {
          ...newAgencyDataStore,
          phoneNumber: newAgencyDataStore?.agencyOwner?.phoneNumber,
        })
      }
      nextTick(() => { importInfoAgency() })
    }, { immediate: true })

    const isCollapsedAgencyAndContactDetail = ref(true)
    function toggleIsCollapsedAgencyAndContactDetail() {
      isCollapsedAgencyAndContactDetail.value = !isCollapsedAgencyAndContactDetail.value
    }

    function modifyPayload() {
      const dataSaveBooking = {
        passengers: [],
        bookingPerson: {
          bookingCode: getBookingCode.value,
          fullName: '',
          identifyNumber: '',
          email: '',
          phoneNumber: '',
          ignoreBuyer: true,
          buyerName: '',
          companyName: '',
          companyAddress: '',
          taxCode: '',
          adultTicketId: 0,
          returnAdultTicketId: 0,
        },
      }

      dataSaveBooking.profileFeeId = store.state['app-train'].selectedProfileServiceFee?.id ?? undefined
      dataSaveBooking.customerId = customerData.value?.id ?? undefined

      stateTicketSelectedArray.value.forEach(storeData => storeData.forEach(ticket => {
        const promo = ticket.applyPromoData
        const calcDiscountPassenger = resolveDiscountPriceByPassengerCode(ticket.ticketPrice, ticket.passengerTargetCode)
        const data = {
          ...ticket,
          totalPrice: ticket.totalPrice - calcDiscountPassenger,
          discountPassenger: calcDiscountPassenger,
          ...(promo && {
            totalPrice: promo.calcPromotion.totalPrice,
            ticketPrice: promo.calcPromotion.ticketPrice,
            insurancePrice: promo.calcPromotion.insurancePrice,
            discountPassenger: promo.calcPromotion.discountPassenger,
            commission: promo.calcPromotion.commission,
            discountSignature: promo.calcPromotion.signature,
            promotions: promo.listPromotionApply.map(it => ({
              detailId: it.detailId,
              masterId: it.masterId,
              discountType: it.discountType,
              discountValue: it.discountValue,
              discountPrice: it.discountPrice,
              voucherCode: null,
              identifyNumber: null,
              ticketListApply: null,
            })),
          }),
        }
        if (promo) {
          delete data.applyPromoData
        }
        dataSaveBooking.passengers.push(data)
      }))

      dataSaveBooking.bookingPerson.fullName = customerData.value.fullName
      dataSaveBooking.bookingPerson.identifyNumber = customerData.value.identifyNumber
      dataSaveBooking.bookingPerson.phoneNumber = customerData.value?.phone || agencyData.value?.phoneNumber
      dataSaveBooking.bookingPerson.email = customerData.value?.email || agencyData.value?.emailAddress

      return dataSaveBooking
    }

    async function saveBookingHandle() {
      try {
        store.dispatch('app/setLoading', true)
        const res = await store.dispatch('app-train/saveBookings', payload.value)
        toastSuccess({ title: 'train.search.result.bookingSuccessful' })
        isBookingCreated.value = true
        router.push({
          name: 'apps-train-ticketDetail',
          params: { id: res.id },
        })
      } catch (error) {
        console.error('Error create ticket', error)
        toastError({ title: 'train.search.result.bookingError' })
      } finally {
        store.dispatch('app/setLoading', false)
      }
    }

    async function handleCreateTicket() {
      if (!stateTicketSelectedArray.value[0]?.length) {
        if (getIsSearchRT.value && tabIndex.value) {
          handleSetTabIndex(0)
        }
        toastError({ title: 'train.search.result.selectTicketAndEnterInfo' })
        return
      }
      if (getIsSearchRT.value && !stateTicketSelectedArray.value[1]?.length) {
        if (!tabIndex.value) {
          handleSetTabIndex(1)
        }
        toastError({ title: 'train.search.result.selectReturnTicketAndEnterInfo' })
        return
      }

      const hasExpired = stateTicketSelectedArray.value.flat().some(tk => tk.isExpired)
      if (hasExpired) {
        toastError({ title: 'train.search.result.ticketExpired' })
        return
      }

      this.$refs.refCreateTicketPaxInput.validate()
        .then(success => {
          if (!success) {
            toastError({ title: 'train.search.result.enterPassengerInfo' })
            if (isCollapsedAgencyAndContactDetail.value) {
              isCollapsedAgencyAndContactDetail.value = !isCollapsedAgencyAndContactDetail.value
            }
            return
          }
          if (!isConfirmPolicy.value) {
            toastWarning({ title: 'train.search.result.acceptTermsAndConditions' })
            return
          }

          const reqData = modifyPayload()
          if (!reqData) {
            return
          }

          payload.value = reqData
          const isOnlyChild = reqData.passengers.every(pax => [2, 7].includes(pax.passengerTargetCode))
          if (isOnlyChild) {
            nextTick(() => this.$bvModal.show('modal-result-train-check-adult-ticket'))
          } else {
            // nextTick(() => saveBooking())
            nextTick(() => {
              isShowConfirm.value = true
            })
          }
        })
    }

    function checkAdultTicketConfirmHandle(data) {
      const { adultTicketId, returnAdultTicketId } = data
      payload.value.bookingPerson.adultTicketId = adultTicketId || 0
      payload.value.bookingPerson.returnAdultTicketId = returnAdultTicketId || 0

      // nextTick(() => saveBooking())
      nextTick(() => {
        isShowConfirm.value = true
      })
    }

    const promotionInput = ref('')

    function handleAppliesPromotion() {
      const allTicketIds = stateTicketSelectedArray.value.map(store => store && store.map(ticket => ticket.ticketId)).flat()
      if (isEmpty(allTicketIds)) {
        toastError({ title: 'train.search.result.selectTicket' })
        return
      }
      store.dispatch('app-train/appliesPromotions', { listTicketsIds: allTicketIds, promotion: promotionInput.value })
    }

    function handleBackToSearch() {
      router.push({ name: 'apps-trainSearch' })
    }

    return {
      isShowConfirm,
      payload,
      loading,
      tabIndex,
      isConfirmPolicy,
      getSearchPayloadArray,
      convertISODateTime,
      formatCurrency,
      payloadSearch,
      getResultTrainArray,
      getTotalCartTicketPrice,

      agencyData,
      customerData,
      defaultCustomerData,
      isCollapsedAgencyAndContactDetail,
      toggleIsCollapsedAgencyAndContactDetail,

      isEmpty,
      promotionInput,
      removeAccentsAndTrimAll,

      saveBookingHandle,
      handleBackToSearch,
      handleCreateTicket,
      handleAppliesPromotion,
      deleteAllSelectTicket,
      checkAdultTicketConfirmHandle,
    }
  },
}
</script>

<style lang="scss">

</style>
